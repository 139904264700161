export const rowKey = "order_no";
import {
  formatOrderSource,
  formatOrderStatus,
  // formatDetailOrderStatus,
  formatGoodsType,
  goodsType,
  orderIsModifyList,
  orderStatusList2,
  // candleOptions,
  formatWarningType,
  formatOrderRefundStatus
} from "@/utils/type";

export const columns = [
  {
    title: "订单ID",
    dataIndex: "order_no",
    align: "center",
    width: 170,
  },
  {
    title: "订单来源",
    dataIndex: "order_source",
    align: "center",
    width: "12%",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatOrderSource(val),
  },
  {
    title: "下单时间",
    dataIndex: "order_add_time",
    align: "center",
    width: "17%",
  },
  {
    title: "自提/收货时间",
    dataIndex: "time",
    align: "center",
    width: "20%",
    slots: {
      customRender: "time",
    },
  },
  {
    title: "完成时间",
    dataIndex: "order_finish_time",
    align: "center",
    width: "17%",
  },
  {
    title: "负责网点",
    dataIndex: "actual_sale_ascription_outlets",
    align: "center",
    width: "14%",
  },
  {
    title: "订单状态",
    dataIndex: "order_status",
    align: "center",
    width: 80,
    slots: {
      customRender: "order_status",
    },
  },
  {
    title: "调度人",
    dataIndex: "dispatch_staff",
    align: "center",
    // width: "12%",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 80,
    slots: { customRender: "operation" },
  },
];

//新多tab列表页
export const list_columns = [
  {
    title: "订单号",
    dataIndex: "order_no",
    align: "center",
    width: 170,
  },
  {
    title: "订单来源",
    dataIndex: "order_source",
    align: "center",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatOrderSource(val),
  },
  {
    title: "下单时间",
    dataIndex: "order_add_time",
    align: "center",
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend',
  },
  {
    title: "收货时间",
    dataIndex: "consignee_recipient_time_start",
    align: "center",
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend',
  },
  {
    title: "实际交付时间",
    dataIndex: "order_finish_time",
    align: "center",
    // width: 130,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend',
  },
  {
    title: "金额",
    dataIndex: "order_amount",
    align: "center",
    width: 80,
  },
  {
    title: "城市",
    dataIndex: "financial_outlets_city",
    align: "center",
    width: 80,
  },
  {
    title: "负责网点",
    dataIndex: "actual_sale_ascription_outlets",
    align: "center",
  },
  {
    title: "配送员",
    dataIndex: "courier_name",
    align: "center",
  },
  {
    title: "配送员电话",
    dataIndex: "courier_phone",
    align: "center",
  },
  {
    title: "类别",
    dataIndex: "order_type",
    align: "center",
    width: 90,
    slots: {
      customRender: "order_type",
    },
    slotsType: "format",
    slotsFunc: (val) => {
      if(val == 1) return "自提"
      if(val == 2) return "配送"
      if(val == 3) return "快递"
      if(val == 4) return "配送+快递"
      return ""
    },
  },
  {
    title: "订单状态",
    dataIndex: "order_status",
    align: "center",
    width: 90,
    slots: {
      customRender: "order_status",
    },
  },
  {
    title: "内部状态",
    dataIndex: "inner_status",
    align: "center",
    width: 90,
    slots: { customRender: 'inner_status' }
  },
  {
    title: "退款状态",
    dataIndex: "refund_status",
    align: "center",
    width: 90,
    slots: { customRender: 'refund_status' },
    slotsType: "all",
    slotsFunc: (val) => {
      return formatOrderRefundStatus(val.order_status) || "——"
    },
  },
  {
    title: "预警类型",
    dataIndex: "warning_type",
    align: "center",
    width: 80,
    slots: { customRender: 'warning_type' },
    slotsType: "format",
    slotsFunc: (val) => formatWarningType(val),
  },
  {
    title: "超时时长",
    dataIndex: "overtime",
    align: "center",
    width: 80,
    slots: { customRender: 'overtime' },
    slotsType: "format",
    slotsFunc: (val) => {
      if(val > 60) {
        return `${Math.floor(val/60)}小时 ${val%60}分`
      }else{
        return `${val}分`
      }
    },
  },
  {
    title: "调度人",
    dataIndex: "dispatch_staff",
    align: "center",
    width: 80,
  },
  {
    title: "预警告知",
    dataIndex: "is_handle",
    align: "center",
    width: 80,
    slots: { customRender: 'is_handle' },
    slotsType: "format",
    slotsFunc: (val) => { val==2?"已处理":"未处理" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 70,
    slots: { customRender: 'operation' }
  },
];

/********************  新增页面列表   *********************************************** */
export const add_columns = [
  // 门店列表
  // {
  //   title: "SKU",
  //   dataIndex: "sku_id",
  //   align: "center",
  // },
  {
    title: "产品属性",
    dataIndex: "goods_type",
    align: "center",
    width: 90,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
    width: "30%",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
    width: "30%",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    slots: { customRender: "number" },
    width: 100,
  },
  {
    title: "商品价格",
    dataIndex: "price",
    align: "center",
    width: 90,
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    width: 100,
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 80,
    slots: { customRender: "operation" },
  },
];

/****************  订单详情页     ***************************************** */
export const detail_columns = [
  {
    title: "分类",
    dataIndex: "goods_type",
    align: "center",
    width: 90,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  // {
  //   title: "SKU码",
  //   dataIndex: "order_goods_id",
  //   align: "center",
  //   width: 80,
  // },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
    slots: {
      customRender: "goods_name",
    },
    slotsType: "all",
    slotsFunc: (val) => {
      return (val.sale_model_desc || "") + val.goods_name
    },
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
    width: "30%",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
    width: "30%",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    width: 80,
  },
  {
    title: "商品单价",
    dataIndex: "unit_price",
    align: "center",
    width: 80,
    slots: { customRender: "unit_price" },
    slotsType: "all",
    slotsFunc: (row) => { return (Number(row.goods_price) / row.number).toFixed(2) },
  },
  {
    title: "商品总价",
    dataIndex: "goods_price",
    align: "center",
    width: 80,
  },
  {
    title: "折后总价",
    dataIndex: "order_price",
    align: "center",
    width: 80,
  },
  {
    title: "是否退费",
    dataIndex: "is_refund",
    align: "center",
    width: 80,
    slots: { customRender: "is_refund" },
    // slotsType: "format",
    // slotsFunc: (val) => formatRefund(val),
  },
  // {
  //   title: "商品状态",
  //   dataIndex: "status",
  //   align: "center",
  //   slots: {
  //     customRender: "status",
  //   },
  // },
];

export const detail_columns_drink = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
  },
  // {
  //   title: "杯型",
  //   dataIndex: "specification_name",
  //   align: "center",
  //   width: "30%",
  // },
  {
    title: "属性",
    dataIndex: "show_desc",
    align: "center",
    width: "60%",
  },
  {
    title: "已做完/总杯数",
    dataIndex: "number",
    align: "center",
    width: 120,
    slots: { customRender: "number" },
    slotsType: "all",
    slotsFunc: (row) => { return row.finish_num +"/"+ row.number },
  },
  {
    title: "商品单价",
    dataIndex: "unit_price",
    align: "center",
    width: 80,
    slots: { customRender: "unit_price" },
    slotsType: "all",
    slotsFunc: (row) => { return (Number(row.goods_price) / row.number).toFixed(2) },
  },
  {
    title: "商品总价",
    dataIndex: "goods_price",
    align: "center",
    width: 80,
  },
  {
    title: "折后总价",
    dataIndex: "order_price",
    align: "center",
    width: 80,
  },
  {
    title: "保温袋",
    dataIndex: "is_pack",
    align: "center",
    width: 80,
    slots: { customRender: "is_pack" },
    slotsType: "format",
    slotsFunc: (val) => {
      return val==2 ? "需要" : "——"
    },
  },
  {
    title: "是否退费",
    dataIndex: "is_refund",
    align: "center",
    width: 80,
    slots: { customRender: "is_refund" },
    // slotsType: "format",
    // slotsFunc: (val) => formatRefund(val),
  },
];

// 赠品列
export const gift_columns = [
  // 门店列表
  {
    title: "SKU",
    dataIndex: "sku_id",
    align: "center",
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "产品属性",
    dataIndex: "goods_type",
    align: "center",
    width: 90,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    width: 80,
  },
];

// 赠券列
export const conpon_columns = [
  // 门店列表
  {
    title: "优惠券",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "券面金额",
    dataIndex: "reduce_cost",
    align: "center",
  },
  {
    title: "消费门槛",
    dataIndex: "least_cost",
    align: "center",
  },
  {
    title: "有效期",
    dataIndex: "term_validity",
    align: "center",
  },
];

export const pay_column = [
  {
    title: "微信",
    dataIndex: "wx_pay_amount",
    align: "center",
  },
  {
    title: "钱包",
    dataIndex: "vip_card_pay_amount",
    align: "center",
  },
  {
    title: "实体卡",
    dataIndex: "stored_value_card_pay_amount",
    align: "center",
  },
  {
    title: "支付宝",
    dataIndex: "alipay_amount",
    align: "center",
  },
  {
    title: "其他支付",
    dataIndex: "other_pay_amount",
    align: "center",
  },
  {
    title: "货到付款",
    dataIndex: "cash_delivery_amount",
    align: "center",
    // slots: { customRender: "cash_delivery_amount" },
  },
  {
    title: "挂账",
    dataIndex: "on_account_amount",
    align: "center",
  },
];

// function formatRefund(val) {
//   return val === 2 ? "是" : "否";
// }

export default {
  rowKey,
  columns,
  list_columns,
  detail_columns,
  detail_columns_drink,
  gift_columns,
  conpon_columns,
  pay_column,

  goodsType,
  // candleOptions,

  orderIsModifyList,
  
  orderStatusOptions: [{
      id: 0, 
      name: "全部" 
  },{
      id: -3, 
      name: "全部有效" 
  }].concat(orderStatusList2.filter(el => el.id != -2))
};

export { 
  formatOrderStatus, 
  formatGoodsType, 
  formatOrderSource, 
  // candleOptions 
};
