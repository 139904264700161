<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef" class="flex"
                    :wrapperCol="{ span: 18 }"
                    :labelCol="{ span: 6 }"
                    :model="searchForm">
        <div style="flex:1;">
          <a-row>
            <a-col :span="8">
              <a-form-model-item prop="order_no" label="订单ID">
                <a-input allowClear
                         v-model="searchForm.order_no"
                         placeholder="请输入订单id"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="pick_up_code" label="提货码">
                <a-input allowClear
                         v-model="searchForm.pick_up_code"
                         placeholder="请输入提货码"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="phone" label="手机号">
                <a-input allowClear
                         v-model="searchForm.phone"
                         placeholder="请输入手机号"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item prop="add_time" label="下单日期">
                <a-range-picker v-model="searchForm.add_time" @change="onFormChange"
                                valueFormat="YYYY-MM-DD" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="consignee_time" label="收货日期">
                <a-range-picker v-model="searchForm.consignee_time" @change="onFormChange"
                                valueFormat="YYYY-MM-DD" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="finish_time" label="完成日期">
                <a-range-picker v-model="searchForm.finish_time" @change="onFormChange"
                                valueFormat="YYYY-MM-DD" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        
        <div class="flex mr-1" style="flex-direction:column;justify-content:space-around;width:170px;text-align:right;">
          <div>
            <a-checkbox @change="onChangeCheckCake" class="ml-3 mr-2"
                      :checked="searchForm.look_cake == 1">蛋糕</a-checkbox>
            <a-button @click="resetForm">重置</a-button>
          </div>
          <div>
            <a-button class="ml-2" type="primary" html-type="submit"
                    @click="handlerSearch">搜本店</a-button>
            <a-button class="ml-2" type="primary" html-type="submit"
                    @click="handlerGlobalSearch">搜所有</a-button>
          </div>
        </div>
      </a-form-model>
    </div>

    <base-table v-if="searchMode=='global'"
                :columnsData="globalColumns"
                rowKey="order_no"
                :tableData="globalTableData"
                :total="globalTotal"
                :getListFunc="globalSearch"
                :page.sync="globalSearchForm.page"
                :pageSize.sync="globalSearchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">全局线上订单</h4>
        </div>
      </template>

      <template #time="{ record }">
        <span>{{formatTime(record.consignee_recipient_time_start)}}~{{formatTime(record.consignee_recipient_time_end.split(' ')[1])}}</span>
      </template>
      <template #order_status="{ text }">
        <!-- -2-已取消（旺店通需自行处理），-1-已取消，1-未支付，2-未审核，3-已分派，4-已完成） -->
        <a-tag class="mr-0"
          :color="text < 0 ? 'red' : (text < 3 ? '' : (text < 4 ? 'blue' : 'green')) "
        >{{ formatDetailOrderStatus(text) }}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="handlerDetailBlank(record)">详情</a-button>
      </template>
    </base-table>

    <base-table v-else
                ref="orderTableRef"
                id="orderTableID"
                :columnsData="newColumns"
                :rowKey="rowKey"
                :tableData="tableData"
                :total="total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">归属本店的线上订单</h4>
        </div>
        <div class="flex justify-between">
          <a-tabs type="card"
                  class="w-full"
                  @change="handlerTypeChange"
                  :activeKey="activeKey">
            <a-tab-pane v-for="item in typeList"
                        :key="item.type_id"
                        :tab="item.type_name"></a-tab-pane>
          </a-tabs>

          <!-- <a-button type="primary" @click="hanlderNewOrder" html-type="submit">新建订单</a-button> -->
        </div>
        <div v-if="activeKey==0" class="ml-2">
          <a-button v-for="subItem in subTypeList" :key="subItem.type_id" 
              type="link" class="p-0 mr-6" :class="activeSubKey==subItem.type_id?'':'grey' "
              @click="handlerSubTypeChange(subItem.type_id)"
          >{{subItem.type_name}}<template v-if="subItem.type_id>0"> ({{subItem.num}})</template></a-button>
        </div>
      </template>

      <template #status="{ text }">
        <!-- <span v-if="text == 1">正常</span> -->
        <a-tag v-if="text == 2" class="mr-0" color="gray">已拆单顺丰</a-tag>
        <a-tag v-if="text == 3" class="mr-0" color="red">已申请退款</a-tag>
        <a-tag v-if="text == 4" class="mr-0" color="red">已退款</a-tag>
      </template>

      <!-- <template #courier_pick_up_goods_status="{ text }">
        <a-tag v-if="text == 0" class="mr-0">无</a-tag>
        <a-tag v-else-if="text == 1" class="mr-0" color="red">未取货</a-tag>
        <a-tag v-else class="mr-0" color="green">已取货</a-tag>
      </template> -->
    </base-table>

    <!-- 抽屉展示订单详情 -->
    <a-drawer class="title-in-page"
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowDetail"
              @close="isShowDetail = false">
      <orderDetail :orderNo="activeRow.suborder_no"
                   v-if="isShowDetail"></orderDetail>
    </a-drawer>

    <!-- 配送员接单二维码 -->
    <div v-show="isShowCode"
         class="qrcode"
         :style="{left: qrLeft, top: qrTop}">
      <div id="qrcode_img"
           ref="qrcode_img"></div>
      <span>使用配送App扫码接单<br>（同小票上的二维码）</span>
    </div>

    <!-- 打印弹窗 -->
    <print-modal v-if="isShowPrint"
                 :show.sync="isShowPrint"
                 :orderNo="activeRow.order_no"
                 :subOrderNo="activeRow.suborder_no" />

  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import moment from "moment"
import pageData from "./columns"
import { columns as globalColumns } from "../../customer-service/order/columns"
import { 
  getShopOrderList, 
  updateOrderStatus,
  getShopOrderCountList1
} from "@/api/shop.js"
import {
  getAllOrderList,
} from "@/api/customer.js"
import { formatDetailOrderStatus } from "@/utils/type"
import { rowSpan } from "@/utils/index"
import orderDetail from './order-detail.vue'
import printModal from "./components/print-modal.vue"

let qrTimer = null

export default {
  components: { 
    printModal, 
    orderDetail 
  },
  data () {
    return {
      searchMode: "",

      newColumns: [],
      isShowDetail: false,

      needShowCode: false,
      isShowCode: false,
      qrLeft: '0px',
      qrTop:  '0px',
      
      isShowPrint: false,

      ...pageData,
      columns: [
        {
          title: "订单ID",
          dataIndex: "order_no",
          align: "center",
          // width: 170,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "下单时间",
          dataIndex: "add_time",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
         {
          title: "自提时间",
          dataIndex: "pickup_time",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
         {
          title: "配送时间",
          dataIndex: "delivery_time",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "配送/自提时间",
          dataIndex: "receive_time",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "完成时间",
          dataIndex: "finish_time",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "完成状态",
          dataIndex: "finish_status",
          align: "center",
          // width: 80,
          width: "7.8%",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "购买商品",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "商品数量",
          dataIndex: "number",
          width: "7.8%",
          align: "center",
        },
        {
          title: "商品状态",
          dataIndex: "status",
          align: "center",
          width: 90,
          slots: {
            customRender: "status",
          },
        },
        {
          title: "配件",
          dataIndex: "accessory",
          align: "center",
        },
        {
          title: "提货码",
          dataIndex: "pick_up_code",
          align: "center",
          // width: 80,
          width: "7%",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "取货状态",
          dataIndex: "courier_pick_up_goods_status",
          align: "center",
          // width: 80,
          customRender: (text, row) => {
            let status = (<a-tag class="mr-0" color="green">已取货</a-tag>)
            if (text === 0) {
              status = (<a-tag class="mr-0">无</a-tag>)
            } else if (text === 1) {
              status = (
                <a-tag class="mr-0 pointer" color="red" onMouseenter={(e) => { this.qrcodeScan(e, row) }} onMouseleave={() => { this.hideQrcode() }}>未取货</a-tag>)
            }

            return {
              children: status,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 160,
          // width: "9.5%",
          customRender: (_, row) => {
            let operation = (
              <div>
                <a type="link" onClick={() => { this.handlerPrint(row) }} >打印</a>
                <a class="ml-3" onClick={() => { this.handlerDetail(row) }}>详情</a>
              </div>
            )
            if (this.activeKey == 1) {
              operation = (
                <div>
                  <a onClick={() => { this.handlerPrint(row) }} >打印</a>
                  <a class="ml-3" onClick={() => { this.handlerBeiHuoFinish(row) }}>备货完成</a>
                  <a class="ml-3" onClick={() => { this.handlerDetail(row) }}>详情</a>
                </div>
              )
            } else if (this.activeKey == 2) {
              operation = (
                <div>
                  <a type="link" onClick={() => { this.handlerPrint(row) }} >打印</a>
                  <a class="ml-3" onClick={() => { this.handlerOrderFinish(row) }}>完成自提</a>
                  <a class="ml-3" onClick={() => { this.handlerDetail(row) }}>详情</a>
                </div>
              )
            } else if (this.activeKey == 4) {
              operation = (
                <div>
                  <a class="ml-3" onClick={() => { this.handlerDetail(row) }}>详情</a>
                </div>
              )
            }
            return {
              children: operation,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
      ],
      qrcode: null,
      activeKey: 0,
      typeList: [
        { type_id: 0, type_name: "全部" },
        { type_id: 1, type_name: "配送未完成" },
        { type_id: 2, type_name: "自提未完成" },
        { type_id: 3, type_name: "已完成" },
        { type_id: 4, type_name: "已取消" },
      ],
      activeSubKey: 0,
      subTypeList: [
        { type_id: 0, type_name: "不限" },
        { 
          type_id: 1, type_name: "今日销售",
          num: 0,
        },
        { 
          type_id: 2, type_name: "今日配送",
          num: 0, 
          isDelivery: true,
        },
        { 
          type_id: 3, type_name: "明日配送",
          num: 0, 
          isDelivery: true,
        },
        { 
          type_id: 4, type_name: "后天及以后配送",
          num: 0, 
          isDelivery: true,
        },
      ],

      isChecked: false,
      wrapperCol: { span: 19 },
      labelCol: { span: 5 },
      total: 0,
      tableData: [],
      baseColumns: [],
      searchForm: {
        order_status: 1,
        order_no: "",
        pick_up_code: "",
        phone: "",
        look_cake: 0,
        add_time: [],
        add_time_start: "",
        add_time_end: "",
        consignee_time: [],
        consignee_time_start: "",
        consignee_time_end: "",
        finish_time: [],
        finish_time_start: "",
        finish_time_end: "",
        page: 1,
        page_count: 20,
      },
      subSearchForm: {
        add_time_start: "",
        add_time_end: "",
        consignee_time_start: "",
        consignee_time_end: "",
      },

      globalSearchForm: {
        order_no: "",
        order_status: 0,
        order_source: 0,
        order_type: 0,
        goods_type: 0,
        pay_mode: 0,
        consignee_recipient_phone: "",
        add_time_start: "",
        add_time_end: "",
        consignee_recipient_time_arr: [],
        consignee_recipient_time_start: "",
        consignee_recipient_time_end: "",
        order_finish_time_arr: [],
        order_finish_time_start: "",
        order_finish_time_end: "",
        page: 1,
        page_count: 20,
      },
      globalTotal: 0,
      globalTableData: [],
      globalColumns,
      formatDetailOrderStatus,

      
      rowKeys: [],
      activeSkuId: 0,
      activeRow: {}
    }
  },
  
  created () {
    let outlets_type = ""
    try{
      let outletsInfo = JSON.parse(localStorage.getItem('outletsInfo')||"")
      outlets_type = outletsInfo && outletsInfo.outlets_type
    }catch(err){
      console.log(err)
    }

    // const outlets_is_delivery = this.$store.getters.userInfo.outlets_info.outlets_is_delivery
    
    // 不能配送 不显示“配送未完成”
    // if(outlets_is_delivery == 1){
    //   this.typeList = this.typeList.filter(el=>el.type_id!=1)
    // }
    // 配送站 不显示“自提未完成”
    if(outlets_type == 2){
      this.typeList = this.typeList.filter(el=>el.type_id!=2)
    }
  },
  mounted () {
    // this.initData()
    this.handlerTypeChange(0)
  },
  methods: {
    moment,
    async initData () {
      this.searchForm.add_time_start = this.searchForm.add_time[0] || ""
      this.searchForm.add_time_end   = this.searchForm.add_time[1] || ""
      this.searchForm.consignee_time_start = this.searchForm.consignee_time[0] || ""
      this.searchForm.consignee_time_end   = this.searchForm.consignee_time[1] || ""
      this.searchForm.finish_time_start = this.searchForm.finish_time[0] || ""
      this.searchForm.finish_time_end   = this.searchForm.finish_time[1] || ""
      // test 感觉有点问题
      // if(this.activeSubKey==0){
      //   this.subSearchForm.add_time_start = this.searchForm.add_time[0] || ""
      //   this.subSearchForm.add_time_end   = this.searchForm.add_time[1] || ""
      // }
      let params = (this.activeKey==0 && this.activeSubKey!=0) ? {
        ...this.searchForm,
        ...this.subSearchForm
      } : {
        ...this.searchForm
      }
      
      const { data, code } = await getShopOrderList(params)
      if (code === 0) {
        let arr = []
        data.list.map((item) => {
          let obj = item
              obj.finish_status = obj.finish_time? "已完成" : ""
              obj.pickup_time = ''
              obj.delivery_time = ''
          // 未支付订单 不显示收款账户
          if(obj.pay_status == 1){
            obj.order_financial_account_name = ""
          }
          if(obj.pick_up_goods_time_start){
            obj.pickup_time = this.formatTime(obj.pick_up_goods_time_start) + '-' + this.formatTime(obj.pick_up_goods_time_end.split(' ')[1])
          }
          if(obj.delivery_time_start){
            obj.delivery_time = this.formatTime(obj.delivery_time_start) + '-' + this.formatTime(obj.delivery_time_end.split(' ')[1])
          }
          obj.receive_time = obj.delivery_time || obj.pickup_time

          item.goods_list.map((el, i) => {
            obj = { ...obj, ...el }
            obj.id = obj.order_no + i
            arr.push(obj)
          })
        })
        this.tableData = rowSpan(arr, "order_no")
        this.total = data.total_count
      }
    },
    onChangeCheckCake(){
      this.searchForm.look_cake == 0 ? (this.searchForm.look_cake = 1) : (this.searchForm.look_cake = 0)
      this.onFormChange()
    },
    resetForm () {
      this.searchMode = ""
      this.searchForm.order_no = ""
      this.searchForm.phone = ""
      this.searchForm.add_time = []
      this.searchForm.consignee_time = []
      this.searchForm.finish_time = []
      this.searchForm.add_time_start = ""
      this.searchForm.add_time_end = ""
      this.searchForm.consignee_time_start = ""
      this.searchForm.consignee_time_end = ""
      this.searchForm.finish_time_start = ""
      this.searchForm.finish_time_end = ""
      this.searchForm.look_cake = 0
      this.searchForm.page = 1
      this.searchForm.page_count = 20
      this.$refs.searchRef.resetFields()
      // this.initData()
      this.onFormChange()
    },

    resetSubSearchForm (clearMainForm) {
      this.subSearchForm.add_time_start = ""
      this.subSearchForm.add_time_end = ""
      this.subSearchForm.consignee_time_start = ""
      this.subSearchForm.consignee_time_end = ""

      if(clearMainForm){
        this.searchMode = ""
        this.searchForm.order_no = ""
        this.searchForm.phone = ""
        this.searchForm.finish_time = []
        this.searchForm.finish_time_start = ""
        this.searchForm.finish_time_end = ""
        // this.searchForm.page = 1
        // this.searchForm.page_count = 20
      } 
    },

    async initShopOrderCountList1 () {
      const { code, data } = await getShopOrderCountList1()
      if (code === 0) {
        this.subTypeList[1].num = data.count_1
        this.subTypeList[2].num = data.count_2
        this.subTypeList[3].num = data.count_3
        this.subTypeList[4].num = data.count_4
      }
    },


    handlerDetail (e) {
      this.activeRow = e
      this.isShowDetail = true
      // this.$router.push({
      //   path: "/shop-order-detail",
      //   query: { id: e.suborder_no },
      // })
    },
    async handlerBeiHuoFinish (e) {
      const { code } = await updateOrderStatus({
        suborder_no: e.suborder_no,
        status: 10,
      })
      if (code === 0) {
        this.$message.success("已完成")
        this.initData()
      }
    },
    async handlerOrderFinish (e) {
      const { message, code } = await updateOrderStatus({
        suborder_no: e.suborder_no,
        status: 30,
      })
      if (code === 0) {
        this.$message.success(message)
        this.initData()
      }
    },
    handlerPrint (e) {
      this.activeRow = e
      this.isShowPrint = true
    },
    handlerTypeChange (e) {
      this.searchMode = ""
      this.activeKey = e
      this.searchForm.order_status = e
      this.searchForm.page = 1
      this.tableData = []

      // 过滤列表显示或者不显示某一列
      if (e == 0) {
        this.handlerSubTypeChange(0)
        this.initShopOrderCountList1()
        return
      } else if (e == 1) {
        this.newColumns = this.columns.filter(
          (item) =>!["finish_time","finish_status","cancel_time","pick_up_code","pickup_time","receive_time"].includes(item.dataIndex)
        )
      } else if (e == 2) {
        this.newColumns = this.columns.filter(
          (item) =>!["courier_pick_up_goods_status","finish_time","finish_status","cancel_time","delivery_time","receive_time"].includes(item.dataIndex)
        )
      } else if (e == 3) {
        this.newColumns = this.columns.filter(
          (item) =>!["courier_pick_up_goods_status","cancel_time","delivery_time","pickup_time","receive_time","finish_status"].includes(item.dataIndex)
        )
      } else {
        this.newColumns = this.columns.filter(
          (item) =>!["courier_pick_up_goods_status","finish_time","finish_status","delivery_time","pickup_time","receive_time","pick_up_code"].includes(item.dataIndex)
        )
      }

      this.initData()
    },

    handlerSubTypeChange(subId){
      this.resetSubSearchForm(subId!=0)
      this.searchForm.order_status = 0

      if(subId==0){
        this.subSearchForm.add_time_start = this.searchForm.add_time_start
        this.subSearchForm.add_time_end   = this.searchForm.add_time_end
      }else if(subId==1){
        this.subSearchForm.add_time_start = this.getDate()
        this.subSearchForm.add_time_end   = this.getDate()
      }else if(subId==2){
        this.searchForm.order_status = 1
        this.subSearchForm.consignee_time_start = this.getDate()
        this.subSearchForm.consignee_time_end   = this.getDate()
      }else if(subId==3){
        this.searchForm.order_status = 1
        this.subSearchForm.consignee_time_start = this.getDate(1)
        this.subSearchForm.consignee_time_end   = this.getDate(1)
      }else if(subId==4){
        this.searchForm.order_status = 1
        this.subSearchForm.consignee_time_start = this.getDate(2)
        this.subSearchForm.consignee_time_end   = this.getDate(300)
      }

      if(subId==0){
        this.newColumns = this.columns.filter(
          (item) =>!["courier_pick_up_goods_status","finish_time","cancel_time","pickup_time","delivery_time"].includes(item.dataIndex)
        )
      }else if(subId==1){
        this.newColumns = this.columns.filter(
          (item) =>!["courier_pick_up_goods_status","finish_time","cancel_time","pickup_time","delivery_time"].includes(item.dataIndex)
        )
      }else{
        this.newColumns = this.columns.filter(
          (item) =>!["finish_time","cancel_time","pick_up_code","pickup_time","receive_time"].includes(item.dataIndex)
        )
      }

      this.activeSubKey = subId
      this.searchForm.page = 1
      this.tableData = []

      this.initData()
    },

    onFormChange(){
      if(this.searchMode == "global"){
        this.handlerGlobalSearch()
      }else{
        this.handlerSearch()
      }
    },

    handlerSearch () {
      // this.initData(this.searchForm)
      this.handlerTypeChange(this.activeKey)
    },

    qrcodeScan (e, row) {//生成二维码
      this.needShowCode = true
      if(qrTimer){
        clearTimeout(qrTimer)
        qrTimer = null
      }
      qrTimer = setTimeout(() => {
        if(!this.needShowCode) return
        // this.$nextTick(() => {
          const { left ,top} = e.srcElement.getBoundingClientRect()
          this.qrLeft = (left - 195) + 'px'
          this.qrTop  = (top  - 335) + 'px'
          this.isShowCode = true
          this.$refs.qrcode_img.innerHTML = ''; // 清除二维码
          const qrdiv = document.getElementById('qrcode_img')
          new QRCode(qrdiv, {
            width:  180,  // 二维码宽度 
            height: 180, // 二维码高度
            text: row.suborder_no
          })
        // })
      }, 200)

    },
    hideQrcode(){
      this.isShowCode = false
      this.needShowCode = false
    },
    formatTime(time){
      if(/\d{2}:\d{2}:\d{2}/.test(time)){
        return time.slice(0, -3)
      }
    },

    getDate(d){
      d = d || 0
      let date = new Date()
          date.setTime(date.getTime()+d*24*3600*1000)
      return this.formatDate(date)
    },
    
    formatDate(date){
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      month = month+1
      if(month < 10){
        month = "0"+month
      }
      if(day < 10){
        day = "0"+day
      }
      return year+"-"+month+"-"+day
    },

    handlerGlobalSearch(){
      this.searchMode = "global"
      this.globalSearchForm.page = 1



      this.globalSearch();
    },
    async globalSearch(){
      this.globalSearchForm.order_no = this.searchForm.order_no
      this.globalSearchForm.consignee_recipient_phone = this.searchForm.phone
      this.globalSearchForm.add_time_arr = this.searchForm.add_time
      this.globalSearchForm.consignee_recipient_time_arr = this.searchForm.consignee_time
      this.globalSearchForm.order_finish_time_arr = this.searchForm.finish_time
      this.globalTableData = []

      if(!this.globalSearchForm.order_no && !this.globalSearchForm.consignee_recipient_phone){
        this.$message.warning('请输入订单号或者手机号')
        return
      }

      this.globalSearchForm.consignee_recipient_time_start = this.globalSearchForm.consignee_recipient_time_arr[0] || ""
      this.globalSearchForm.consignee_recipient_time_end   = this.globalSearchForm.consignee_recipient_time_arr[1] || ""
      if (this.globalSearchForm.order_finish_time_arr.length > 0) {
        this.globalSearchForm.order_finish_time_start = this.globalSearchForm.order_finish_time_arr[0] + " 00:00:00"
        this.globalSearchForm.order_finish_time_end   = this.globalSearchForm.order_finish_time_arr[1] + " 23:59:59"
      }else{
        this.globalSearchForm.order_finish_time_start = ""
        this.globalSearchForm.order_finish_time_end   = ""
      }
      const { data, code } = await getAllOrderList(this.globalSearchForm)
      if (code === 0) {
        this.globalTableData = data.list
        this.globalTotal = data.total_count
      }
    },
    
    handlerDetailBlank(row) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { 
          show: 1,
          order_no: row.order_no,
        },
      });
      window.open(newPage.href, "_blank");
    },

    // hanlderNewOrder() {
    //   this.$router.push(`/shop-order-add`)
    // },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
.pointer {
  cursor: pointer;
}
.qrcode{
  position: absolute;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgb(150 150 150);
  text-align: center;
  pointer-events: none;
}
#qrcode_img{
  width: 180px;
  height: 180px;
  margin-bottom: 5px;
}
.grey{
  color: #999;
}
</style>
